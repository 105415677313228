<template>
    <div class="privacy-policy small-intro" v-if="pageData">
        <div class="intro-section container">
            <div v-if="this.pageData.attributes.title">
                <h1 class="light" > {{this.pageData.attributes.title}}</h1>
                <p v-html="this.pageData.attributes.body.value">
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"privacy-policy",
    components: {
    },
    data:()=>{
        return {
            currentPageId:"85a45658-4b83-407a-9feb-1e9d5f3d2b63",
            pageData:[],
        }
    },
    methods:{
    },
    mounted(){
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/page/${this.currentPageId}`, {
            method: "GET",
            mode: "cors",
        })
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            this.pageData = json.data;

})
        .catch(function (error) {
            console.log("Request failed", error);
        });
    }
}
</script>
<style lang="scss" scoped>
</style>